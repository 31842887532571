<template>
    <div class="modifyPaymentPassword-wrap">
        <van-nav-bar
            title="修改支付密码"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="modifyPaymentPassword-main-wrap">
            <van-field
                v-model="changeParams.password"
                type="password"
                label="旧支付密码"
                maxlength="6"
                :formatter="formatter"
                clearable
                autocomplete="off"
                placeholder="请输入旧密码"
            />
            <van-field
                v-model="changeParams.new_password"
                :type="inputPwType"
                label="新支付密码"
                maxlength="6"
                :formatter="formatter"
                clearable
                autocomplete="off"
                placeholder="请输入新密码"
            >
                <template #button>
                    <span @click="changePassWordType">
                        <van-icon name="closed-eye" v-if="showEye" />
                        <van-icon name="eye-o" v-else />
                    </span>
                </template>
            </van-field>
            <van-field
                v-model="changeParams.new_confirm_password"
                :type="inputCPwType"
                label="确认支付密码"
                maxlength="6"
                :formatter="formatter"
                clearable
                autocomplete="off"
                placeholder="确认支付密码"
            >
                <template #button>
                    <span @click="changeCPassWordType">
                        <van-icon name="closed-eye" v-if="showCEye" />
                        <van-icon name="eye-o" v-else />
                    </span>
                </template>
            </van-field>
            <div class="comfirm-btn-box">
                <van-button block @click="handleChangePassword">确认</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import {changePayPassword} from '@/api/api'
import { Toast } from 'vant'

export default {
    setup () {
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        const inputPwType = ref("password");
        const inputCPwType = ref("password");
        const showEye = ref(true);
        const showCEye = ref(true);
        const changePassWordType = () => {
            showEye.value = !showEye.value;
            if (showEye.value) {
                inputPwType.value = "password";
            } else {
                inputPwType.value = "text";
            }
        };
        const changeCPassWordType = () => {
            showCEye.value = !showCEye.value;
            if (showCEye.value) {
                inputCPwType.value = "password";
            } else {
                inputCPwType.value = "text";
            }
        };
        const changeParams = reactive({
            password: '',
            new_password: '',
            new_confirm_password: ''
        })
        // 修改密码
        const handleChangePassword = () => {
            if(!changeParams.password) {
                return Toast.fail('请输入旧密码')
            } else if(!changeParams.new_password) {
                return Toast.fail('请输入新密码')
            } else if(!changeParams.new_confirm_password) {
                return Toast.fail('请再次输入新密码')
            } else if(changeParams.new_password != changeParams.new_confirm_password) {
                return Toast.fail('两次新密码不一致')
            } else {
                changePayPassword(changeParams).then(res => {
                    if(res.result == 200000) {
                        Toast.success('修改成功')
                        history.back()
                    } else {
                        Toast.fail(res.msg)
                    }
                })
            }
            
        }
        return {
            onClickLeft,
            formatter,
            inputPwType,
            inputCPwType,
            showEye,
            showCEye,
            changePassWordType,
            changeCPassWordType,
            changeParams,
            handleChangePassword
        }
    }
}
</script>

<style lang="less" scoped>
.modifyPaymentPassword-wrap {
    .modifyPaymentPassword-main-wrap {
        height: calc(100vh - 46px);
        background: var(--mainBgColor);
        .comfirm-btn-box {
            margin-top: 20px;
            padding: 0 16px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
}
</style>